* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Century Gothic";
}

.App {
    background-color: rgb(255, 255, 158);

}

h1 {
    text-align: center;
    text-decoration: overline underline blue;
    padding: 5px;
}

.breed {
    font-weight: bold;
}

.dogs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

}

.dogsList {
    border: 2px solid rgb(78, 200, 237);
    border-radius: 10px;
    margin: 10px;
    padding: 0px 10px;
    background-color: lightpink;
    text-align: center;
}

img {
    width: 150px;
    border-radius: 8px;

}

.sub {
    text-align: justify;
    padding: 15px 15px;

}